.main_div_client_review{
    width: 100%;
    height: auto;
}
.below_main_div{
    margin-left: 5%;
    width: 90%;
}
.backg_black{
    width: 490px !important;
    height: 350px;
border: 2px solid #F6FAFF;
background: #0E0E10;
box-shadow: 0px 4px 20px 0px rgba(2, 2, 2, 0.20);
margin-left: 10%;

}
.space{
    padding-top: 4%;
}
.stars_img{
    width: auto;
height: 25px;
background-size: contain;
margin-left: 1%;
background-image: url("./../../Assets/Star.png");
}
.paragraph{
    color: #FFF;
    font-family: "Poppins", sans-serif;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-top: 5%;
}
.ellipse_heading{
    color: #FFF;
    font-family: "Poppins", sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.48px;
}
.ellipse_text{
    color: #FFF;
    font-family: "Poppins", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.36px;
margin-top: 30%;
}
.ellipse_img{
    margin-top: 25%;
    width: auto;
    height: 90%;
    background-size: contain;
    background-image: url("./../../Assets/Ellipse 12.png");
}
.ellipse11_img{
    margin-top: 25%;
    width: auto;
    height: 90%;
    background-size: contain;
    background-image: url("./../../Assets/Ellipse 11.png");
}
.ellipse13_img{
    margin-top: 25%;
    width: auto;
    height: 90%;
    background-size: contain;
    background-image: url("./../../Assets/Ellipse 13.png");
}
.ellipseNew_img{
    margin-top: 25%;
    width: auto;
    height: 90%;
    background-size: contain;
    background-image: url("./../../Assets/Ellipse 12 (1).png");
}
.main_heading_what_our_client{
    text-align: center;
}

.what_our_client_para{
    color: #0E0E10;
 
    font-family: "Poppins", sans-serif;
    font-size: 58px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.16px;
    color: #0E0E10;
text-align: center;
margin-top: 5%;
margin-bottom: 5%;
}
main_button_slider_div{
    position: relative;
}
.slick-prev{
    position: absolute;
    top: -32%;
    left: 89%;
}
.slick-next{
    position: absolute;
    top: -32%;
    left: 95%;
}

.slick-prev:before {
    content: none;
    opacity: 10;
    content: url("./../../Assets/Left button.png");
}

.slick-next:before {
    content: none;
    opacity: 10;
    content: url("./../../Assets/Right Button.png");
}

@media screen and (min-width: 1500px) and (max-width: 1768px){
    .main_div_client_review{
        width: 100%;
        height: auto;
    }
    .below_main_div{
        margin-left: 5%;
        width: 90%;
    }
    .backg_black{
        width: 400px !important;
        height: 350px;
    border: 2px solid #F6FAFF;
    background: #0E0E10;
    box-shadow: 0px 4px 20px 0px rgba(2, 2, 2, 0.20);
    margin-left: 10%;
    
    }
    .space{
        padding-top: 4%;
    }
    .stars_img{
        width: auto;
    height: 25px;
    background-size: contain;
    margin-left: 1%;
    background-image: url("./../../Assets/Star.png");
    }
    .paragraph{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5%;
    }
    .ellipse_heading{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    }
    .ellipse_text{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-top: 30%;
    }
    .ellipse_img{
        margin-top: 25%;
        width: auto;
        height: 80%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12.png");
    }
    .ellipse11_img{
        margin-top: 25%;
        width: auto;
        height: 81%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 11.png");
    }
    .ellipse13_img{
        margin-top: 25%;
        width: auto;
        height: 80%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 13.png");
    }
    .ellipseNew_img{
        margin-top: 25%;
        width: auto;
        height: 80%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12 (1).png");
    }
    .main_heading_what_our_client{
        text-align: center;
    }
    
    .what_our_client_para{
        color: #0E0E10;
     
        font-family: "Poppins", sans-serif;
        font-size: 58px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        color: #0E0E10;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    }
    main_button_slider_div{
        position: relative;
    }
    .slick-prev{
        position: absolute;
        top: -32%;
        left: 83%;
    }
    .slick-next{
        position: absolute;
        top: -32%;
        left: 90%;
    }
    
    .slick-prev:before {
        content: none;
        opacity: 10;
        content: url("./../../Assets/Left button.png");
    }
    
    .slick-next:before {
        content: none;
        opacity: 10;
        content: url("./../../Assets/Right Button.png");
    }
}

@media screen and (min-width: 1200px) and (max-width: 1500px){
    .main_div_client_review{
        width: 100%;
        height: auto;
    }
    .below_main_div{
        margin-left: 5%;
        width: 90%;
    }
    .backg_black{
        width: 265px !important;
        height: 290px;
    border: 2px solid #F6FAFF;
    background: #0E0E10;
    box-shadow: 0px 4px 20px 0px rgba(2, 2, 2, 0.20);
    margin-left: 10%;
    
    }
    .space{
        padding-top: 4%;
    }
    .stars_img{
        width: auto;
    height: 25px;
    background-size: contain;
    margin-left: 1%;
    background-image: url("./../../Assets/Star.png");
    }
    .paragraph{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5%;
    }
    .ellipse_heading{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    }
    .ellipse_text{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 6px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-top: 30%;
    }
    .ellipse_img{
        margin-top: 25%;
        width: auto;
        height: 73%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12.png");
    }
    .ellipse11_img{
        margin-top: 25%;
        width: auto;
        height: 61%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 11.png");
    }
    .ellipse13_img{
        margin-top: 25%;
        width: auto;
        height: 72%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 13.png");
    }
    .ellipseNew_img{
        margin-top: 25%;
        width: auto;
        height: 72%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12 (1).png");
    }
    .main_heading_what_our_client{
        text-align: center;
    }
    
    .what_our_client_para{
        color: #0E0E10;
     
        font-family: "Poppins", sans-serif;
        font-size: 58px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        color: #0E0E10;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    }
    main_button_slider_div{
        position: relative;
    }
    .slick-prev{
        position: absolute;
        top: -23%;
        left: 81.5%;
    }
    .slick-next{
        position: absolute;
        top: -23%;
        left: 88%;
    }
    
    .slick-prev:before {
        content: none;
        opacity: 10;
        content: url("./../../Assets/Left button.png");
        /* max-width:auto ;
        height: 100%;
        object-fit: cover; */
    }
    
    .slick-next:before {
        content: none;
        opacity: 10;
        content: url("./../../Assets/Right Button.png");
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px){
    .main_div_client_review{
        width: 100%;
        height: auto;
    }
    .below_main_div{
        margin-left: 5%;
        width: 90%;
    }
    .backg_black{
        width: 220px !important;
        height: 250px;
    border: 2px solid #F6FAFF;
    background: #0E0E10;
    box-shadow: 0px 4px 20px 0px rgba(2, 2, 2, 0.20);
    margin-left: 10%;
    
    }
    .space{
        padding-top: 4%;
    }
    .stars_img{
        width: auto;
    height: 25px;
    background-size: contain;
    margin-left: 1%;
    background-image: url("./../../Assets/Star.png");
    }
    .paragraph{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5%;
    }
    .ellipse_heading{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    }
    .ellipse_text{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 4px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-top: 30%;
    }
    .ellipse_img{
        margin-top: 25%;
        width: auto;
        height: 65%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12.png");
    }
    .ellipse11_img{
        margin-top: 25%;
        width: auto;
        height: 64%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 11.png");
    }
    .ellipse13_img{
        margin-top: 25%;
        width: auto;
        height: 65%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 13.png");
    }
    .ellipseNew_img{
        margin-top: 25%;
        width: auto;
        height: 65%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12 (1).png");
    }
    .main_heading_what_our_client{
        text-align: center;
    }
    
    .what_our_client_para{
        color: #0E0E10;
     
        font-family: "Poppins", sans-serif;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        color: #0E0E10;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    }
    main_button_slider_div{
        position: relative;
    }
    .slick-prev{
        position: absolute;
        top: -23%;
        left: 79%;
    }
    .slick-next{
        position: absolute;
        top: -23%;
        left: 87%;
    }
    
    .slick-prev:before {
        content: none;
        opacity: 10;
        content: url("./../../Assets/Left button.png");
        /* max-width:auto ;
        height: 100%;
        object-fit: cover; */
    }
    
    .slick-next:before {
        content: none;
        opacity: 10;
        content: url("./../../Assets/Right Button.png");
    }
}

@media screen and (min-width: 992px) and (max-width: 1023px){
    .main_div_client_review{
        width: 100%;
        height: auto;
    }
    .below_main_div{
        margin-left: 5%;
        width: 90%;
    }
    .backg_black{
        width: 210px !important;
        height: 220px;
    border: 2px solid #F6FAFF;
    background: #0E0E10;
    box-shadow: 0px 4px 20px 0px rgba(2, 2, 2, 0.20);
    margin-left: 10%;
    
    }
    .space{
        padding-top: 4%;
    }
    .stars_img{
        width: auto;
    height: 25px;
    background-size: contain;
    margin-left: 1%;
    background-image: url("./../../Assets/Star.png");
    }
    .paragraph{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5%;
    }
    .ellipse_heading{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    }
    .ellipse_text{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 4px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-top: 25%;
    }
    .ellipse_img{
        margin-top: 25%;
        width: auto;
        height: 60%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12.png");
    }
    .ellipse11_img{
        margin-top: 25%;
        width: auto;
        height: 50%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 11.png");
    }
    .ellipse13_img{
        margin-top: 25%;
        width: auto;
        height: 56%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 13.png");
    }
    .ellipseNew_img{
        margin-top: 25%;
        width: auto;
        height: 56%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12 (1).png");
    }
    .main_heading_what_our_client{
        text-align: center;
    }
    
    .what_our_client_para{
        color: #0E0E10;
     
        font-family: "Poppins", sans-serif;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        color: #0E0E10;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    }
    main_button_slider_div{
        position: relative;
    }
    .slick-prev{
        position: absolute;
        top: -20%;
        left: 80%;
    }
    .slick-next{
        position: absolute;
        top: -20%;
        left: 87%;
    }
    
    .slick-prev:before {
        content: "";
            display: inline-block; /* or block, depending on the context */
            width: 50px; /* Set the desired width */
            height: 50px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Left button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
    }
    .slick-next:before {
            content: "";
            display: inline-block; /* or block, depending on the context */
            width: 50px; /* Set the desired width */
            height: 50px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Right Button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
        
        
    }
}

@media screen and (min-width: 811px) and (max-width: 991px){
    .main_div_client_review{
        width: 100%;
        height: auto;
    }
    .below_main_div{
        margin-left: 5%;
        width: 90%;
    }
    .backg_black{
        width: 210px !important;
        height: 220px;
    border: 2px solid #F6FAFF;
    background: #0E0E10;
    box-shadow: 0px 4px 20px 0px rgba(2, 2, 2, 0.20);
    margin-left: 10%;
    
    }
    .space{
        padding-top: 4%;
    }
    .stars_img{
        width: auto;
    height: 25px;
    background-size: contain;
    margin-left: 1%;
    background-image: url("./../../Assets/Star.png");
    }
    .paragraph{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5%;
    }
    .ellipse_heading{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    }
    .ellipse_text{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 4px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-top: 25%;
    }
    .ellipse_img{
        margin-top: 25%;
        width: auto;
        height: 60%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12.png");
    }
    .ellipse11_img{
        margin-top: 25%;
        width: auto;
        height: 50%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 11.png");
    }
    .ellipse13_img{
        margin-top: 25%;
        width: auto;
        height: 56%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 13.png");
    }
    .ellipseNew_img{
        margin-top: 25%;
        width: auto;
        height: 56%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12 (1).png");
    }
    .main_heading_what_our_client{
        text-align: center;
    }
    
    .what_our_client_para{
        color: #0E0E10;
     
        font-family: "Poppins", sans-serif;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        color: #0E0E10;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    }
    main_button_slider_div{
        position: relative;
    }
    .slick-prev{
        position: absolute;
        top: -15%;
        left: 83%;
    }
    .slick-next{
        position: absolute;
        top: -15%;
        left: 89%;
    }
    
    .slick-prev:before {
        content: "";
            display: inline-block; /* or block, depending on the context */
            width: 40px; /* Set the desired width */
            height: 40px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Left button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
    }
    .slick-next:before {
            content: "";
            display: inline-block; /* or block, depending on the context */
            width: 40px; /* Set the desired width */
            height: 40px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Right Button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
        
        
    }
}

@media screen and (min-width: 768px) and (max-width: 810px){
    .main_div_client_review{
        width: 100%;
        height: auto;
    }
    .below_main_div{
        margin-left: 5%;
        width: 90%;
    }
    .backg_black{
        width: 210px !important;
        height: 220px;
    border: 2px solid #F6FAFF;
    background: #0E0E10;
    box-shadow: 0px 4px 20px 0px rgba(2, 2, 2, 0.20);
    margin-left: 10%;
    
    }
    .space{
        padding-top: 4%;
    }
    .stars_img{
        width: auto;
    height: 25px;
    background-size: contain;
    margin-left: 1%;
    background-image: url("./../../Assets/Star.png");
    }
    .paragraph{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5%;
    }
    .ellipse_heading{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    }
    .ellipse_text{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 4px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-top: 25%;
    }
    .ellipse_img{
        margin-top: 25%;
        width: auto;
        height: 60%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12.png");
    }
    .ellipse11_img{
        margin-top: 25%;
        width: auto;
        height: 50%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 11.png");
    }
    .ellipse13_img{
        margin-top: 25%;
        width: auto;
        height: 56%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 13.png");
    }
    .ellipseNew_img{
        margin-top: 25%;
        width: auto;
        height: 56%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12 (1).png");
    }
    .main_heading_what_our_client{
        text-align: center;
    }
    
    .what_our_client_para{
        color: #0E0E10;
     
        font-family: "Poppins", sans-serif;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        color: #0E0E10;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    }
    main_button_slider_div{
        position: relative;
    }
    .slick-prev{
        position: absolute;
        top: -15%;
        left: 86%;
    }
    .slick-next{
        position: absolute;
        top: -15%;
        left: 94%;
    }
    
    .slick-prev:before {
        content: "";
            display: inline-block; /* or block, depending on the context */
            width: 40px; /* Set the desired width */
            height: 40px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Left button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
    }
    .slick-next:before {
            content: "";
            display: inline-block; /* or block, depending on the context */
            width: 40px; /* Set the desired width */
            height: 40px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Right Button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
        
        
    }
}

@media screen and (min-width: 576px) and (max-width: 767px){
    .main_div_client_review{
        width: 100%;
        height: auto;
    }
    .below_main_div{
        margin-left: 5%;
        width: 90%;
    }
    .backg_black{
        width: 150px !important;
        height: 200px;
    border: 2px solid #F6FAFF;
    background: #0E0E10;
    box-shadow: 0px 4px 20px 0px rgba(2, 2, 2, 0.20);
    margin-left: 10%;
    
    }
    .space{
        padding-top: 4%;
    }
    .stars_img{
        width: auto;
    height: 25px;
    background-size: contain;
    margin-left: 1%;
    background-image: url("./../../Assets/Star.png");
    }
    .paragraph{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5%;
    }
    .ellipse_heading{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 6px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    }
    .ellipse_text{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-top: 15%;
    }
    .ellipse_img{
        margin-top: 35%;
        width: auto;
        height: 63%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12.png");
    }
    .ellipse11_img{
        margin-top: 35%;
        width: auto;
        height: 63%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 11.png");
    }
    .ellipse13_img{
        margin-top: 35%;
        width: auto;
        height: 63%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 13.png");
    }
    .ellipseNew_img{
        margin-top: 35%;
        width: auto;
        height: 63%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12 (1).png");
    }
    .main_heading_what_our_client{
        text-align: center;
    }
    
    .what_our_client_para{
        color: #0E0E10;
     
        font-family: "Poppins", sans-serif;
        font-size: 31px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        color: #0E0E10;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    }
    main_button_slider_div{
        position: relative;
    }
    .slick-prev{
        position: absolute;
        top: -12%;
        left: 86%;
    }
    .slick-next{
        position: absolute;
        top: -12%;
        left: 94%;
    }
    
    .slick-prev:before {
        content: "";
            display: inline-block; /* or block, depending on the context */
            width: 35px; /* Set the desired width */
            height: 35px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Left button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
    }
    .slick-next:before {
            content: "";
            display: inline-block; /* or block, depending on the context */
            width: 35px; /* Set the desired width */
            height: 35px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Right Button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
        
        
    }
}

@media screen and (min-width: 401px) and (max-width: 575px){
    .main_div_client_review{
        width: 100%;
        height: auto;
    }
    .below_main_div{
        margin-left: 5%;
        width: 90%;
    }
    .backg_black{
        width: 110px !important;
        height: 180px;
    border: 2px solid #F6FAFF;
    background: #0E0E10;
    box-shadow: 0px 4px 20px 0px rgba(2, 2, 2, 0.20);
    margin-left: 10%;
    
    }
    .space{
        padding-top: 4%;
    }
    .stars_img{
        /* width: 1px;
    height: 10px;
    background-size: contain;
    margin-left: -10%; */
        width: 20px; /* Set the desired width of each star */
        height: 10px; /* Set the desired height of each star */
        background-size: contain; /* Fit the entire image within the container */
        background-repeat: no-repeat;
        margin-left: -15%;
        /* Replace the below URL with the actual path to your star image */
    background-image: url("./../../Assets/Star.png");
    }
    .paragraph{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5%;
    text-align: justify;
    margin-bottom: 15%;
    }
    .ellipse_heading{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    }
    .ellipse_text{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 4px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-top: 15%;
    }
    .ellipse_img{
        margin-top: 35%;
        width: 29px;
        height: 73%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12.png");
    }
    .ellipse11_img{
        margin-top: 35%;
        width: 29px;
        height: 70%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 11.png");
    }
    .ellipse13_img{
        margin-top: 35%;
        width: 29px;
        height: 70%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 13.png");
    }
    .ellipseNew_img{
        margin-top: 35%;
        width: 29px;
        height: 70%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12 (1).png");
    } 
    .main_heading_what_our_client{
        text-align: center;
    }
    
    .what_our_client_para{
        color: #0E0E10;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        color: #0E0E10;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 15%;
    }
    main_button_slider_div{
        position: relative;
    }
    .slick-prev{
        position: absolute;
        top: -15%;
        left: 73%;
    }
    .slick-next{
        position: absolute;
        top: -15%;
        left: 85%;
    }
    
    .slick-prev:before {
        content: "";
            display: inline-block; /* or block, depending on the context */
            width: 35px; /* Set the desired width */
            height: 35px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Left button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
    }
    .slick-next:before {
            content: "";
            display: inline-block; /* or block, depending on the context */
            width: 35px; /* Set the desired width */
            height: 35px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Right Button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
        
        
    }
}

@media screen and (min-width: 320px) and (max-width: 400px){
    .main_div_client_review{
        width: 100%;
        height: auto;
    }
    .below_main_div{
        margin-left: 5%;
        width: 90%;
    }
    .backg_black{
        width: 80px !important;
        height: 200px;
    border: 2px solid #F6FAFF;
    background: #0E0E10;
    box-shadow: 0px 4px 20px 0px rgba(2, 2, 2, 0.20);
    margin-left: 10%;
    
    }
    .space{
        padding-top: 4%;
    }
    .stars_img{
        /* width: 1px;
    height: 10px;
    background-size: contain;
    margin-left: -10%; */
        width: 20px; /* Set the desired width of each star */
        height: 10px; /* Set the desired height of each star */
        background-size: contain; /* Fit the entire image within the container */
        background-repeat: no-repeat;
        margin-left: -15%;
        /* Replace the below URL with the actual path to your star image */
    background-image: url("./../../Assets/Star.png");
    }
    .paragraph{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5%;
    text-align: justify;
    margin-bottom: 15%;
    }
    .ellipse_heading{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    }
    .ellipse_text{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 4px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-top: 15%;
    }
    .ellipse_img{
        margin-top: 35%;
        width: 29px;
        height: 73%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12.png");
    }
    .ellipse11_img{
        margin-top: 35%;
        width: 29px;
        height: 70%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 11.png");
    }
    .ellipse13_img{
        margin-top: 35%;
        width: 29px;
        height: 70%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 13.png");
    }
    .ellipseNew_img{
        margin-top: 35%;
        width: 29px;
        height: 70%;
        background-size: contain;
        background-image: url("./../../Assets/Ellipse 12 (1).png");
    } 
    .main_heading_what_our_client{
        text-align: center;
    }
    
    .what_our_client_para{
        color: #0E0E10;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        color: #0E0E10;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 15%;
    }
    main_button_slider_div{
        position: relative;
    }
    .slick-prev{
        position: absolute;
        top: -12%;
        left: 73%;
    }
    .slick-next{
        position: absolute;
        top: -12%;
        left: 85%;
    }
    
    .slick-prev:before {
        content: "";
            display: inline-block; /* or block, depending on the context */
            width: 35px; /* Set the desired width */
            height: 35px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Left button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
    }
    .slick-next:before {
            content: "";
            display: inline-block; /* or block, depending on the context */
            width: 35px; /* Set the desired width */
            height: 35px; /* Set the desired height */
            background-size: contain;
            background-image: url("./../../Assets/Right Button.png");
            /* Optionally, you can also set other background properties like background-size, background-repeat, etc. */
        
        
    }
}

