.Ques_main_class {
  width: auto;
  background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
  height: auto;
  padding-top: 3%;
}
.Ques_highlight {
  color: #0e0e10;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.16px;
}
.Ques_heading {
  color: #0e0e10;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.16px;
  text-align: center;
}
.ending_space{
    padding-top: 4%;
}
.accordion-button{
    background: linear-gradient(90deg, #c3c3c3 -25%, #F4F4F4 60.42%);
    color: #0E0E10;
    font-family: "Poppins", sans-serif;
font-size: 19px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.48px;
border: none !important;
border-color: none !important;
border-style: none !important;
/* background-image: url("./../../Assets/arrow before.png"); */
}
.accordion{
    background: linear-gradient(90deg, #c3c3c3 -25%, #F4F4F4 60.42%);
    border: none !important;
    border-color: none !important;
    border-style: none !important;
}
.accordion-item:first-of-type .accordion-button{
    border: none !important;
    border-color: none !important;
    border-style: none !important;
}
.accordion-item{
    border: none !important;
}
.accordion-button::after{
    background-image: url("./../../Assets/arrow before.png");

}
.accordion-button:not(.collapsed)::after {
    background-image: url("./../../Assets/arrow after.png")
}
.accordion-button:not(.collapsed) {
    color: #0E0E10;
    background: linear-gradient(90deg, #c3c3c3 -25%, #F4F4F4 60.42%);
    box-shadow: none;
}
.accordion-body{
    background: linear-gradient(90deg, #c3c3c3 -25%, #F4F4F4 60.42%);
}
.accordion-item:first-of-type .accordion-button{
    background: linear-gradient(90deg, #c3c3c3 -25% #F4F4F4 60.42%);
}
.hr_line {
    width: 100%;
    height:3px !important;
    border-width:1;
    color:black !important;
    background-color:#000000 !important;
    margin: 0rem 0 !important;
}

@media screen and (min-width: 1615px) and (max-width: 1662px){
    .Ques_main_class {
        width: auto;
        background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
        height: auto;
        padding-top: 3%;
      }
      .Ques_highlight {
        color: #0e0e10;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.16px;
      }
      .Ques_heading {
        color: #0e0e10;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
      }
      .ending_space{
          padding-top: 4%;
      }
      .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -13%, #F4F4F4 60.42%);
          color: #0E0E10;
          font-family: "Poppins", sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.48px;
      border: none !important;
      border-color: none !important;
      border-style: none !important;
      /* background-image: url("./../../Assets/arrow before.png"); */
      }
      .accordion{
          background: linear-gradient(90deg, #c3c3c3 -13%, #F4F4F4 60.42%);
          border: none !important;
          border-color: none !important;
          border-style: none !important;
      }
      .accordion-button:not(.collapsed) {
          color: #0E0E10;
          background: linear-gradient(90deg, #c3c3c3 -13%, #F4F4F4 60.42%);
          box-shadow: none;
      }
      .accordion-body{
          background: linear-gradient(90deg, #c3c3c3 -13%, #F4F4F4 60.42%);
      }
      .accordion-item:first-of-type .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -13% #F4F4F4 60.42%);
      }
}

@media screen and (min-width: 1501px) and (max-width: 1562px){
    .Ques_main_class {
        width: auto;
        background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
        height: auto;
        padding-top: 3%;
      }
      .Ques_highlight {
        color: #0e0e10;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.16px;
      }
      .Ques_heading {
        color: #0e0e10;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
      }
      .ending_space{
          padding-top: 4%;
      }
      .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -8%, #F4F4F4 60.42%);
          color: #0E0E10;
          font-family: "Poppins", sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.48px;
      border: none !important;
      border-color: none !important;
      border-style: none !important;
      /* background-image: url("./../../Assets/arrow before.png"); */
      }
      .accordion{
          background: linear-gradient(90deg, #c3c3c3 -8%, #F4F4F4 60.42%);
          border: none !important;
          border-color: none !important;
          border-style: none !important;
      }
      .accordion-button:not(.collapsed) {
          color: #0E0E10;
          background: linear-gradient(90deg, #c3c3c3 -8%, #F4F4F4 60.42%);
          box-shadow: none;
      }
      .accordion-body{
          background: linear-gradient(90deg, #c3c3c3 -8%, #F4F4F4 60.42%);
      }
      .accordion-item:first-of-type .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -8% #F4F4F4 60.42%);
      }
}

@media screen and (min-width: 1400px) and (max-width: 1500px){
    .Ques_main_class {
        width: auto;
        background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
        height: auto;
        padding-top: 3%;
      }
      .Ques_highlight {
        color: #0e0e10;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.16px;
      }
      .Ques_heading {
        color: #0e0e10;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
      }
      .ending_space{
          padding-top: 4%;
      }
      .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -7%, #F4F4F4 60.42%);
          color: #0E0E10;
          font-family: "Poppins", sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.48px;
      border: none !important;
      border-color: none !important;
      border-style: none !important;
      /* background-image: url("./../../Assets/arrow before.png"); */
      }
      .accordion{
          background: linear-gradient(90deg, #c3c3c3 -7%, #F4F4F4 60.42%);
          border: none !important;
          border-color: none !important;
          border-style: none !important;
      }
      .accordion-button:not(.collapsed) {
          color: #0E0E10;
          background: linear-gradient(90deg, #c3c3c3 -7%, #F4F4F4 60.42%);
          box-shadow: none;
      }
      .accordion-body{
          background: linear-gradient(90deg, #c3c3c3 -7%, #F4F4F4 60.42%);
      }
      .accordion-item:first-of-type .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -7% #F4F4F4 60.42%);
      }
}

@media screen and (min-width: 1351px) and (max-width: 1399px){
    .Ques_main_class {
        width: auto;
        background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
        height: auto;
        padding-top: 3%;
      }
      .Ques_highlight {
        color: #0e0e10;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.16px;
      }
      .Ques_heading {
        color: #0e0e10;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
      }
      .ending_space{
          padding-top: 4%;
      }
      .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -12%, #F4F4F4 60.42%);
          color: #0E0E10;
          font-family: "Poppins", sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.48px;
      border: none !important;
      border-color: none !important;
      border-style: none !important;
      /* background-image: url("./../../Assets/arrow before.png"); */
      }
      .accordion{
          background: linear-gradient(90deg, #c3c3c3 -12%, #F4F4F4 60.42%);
          border: none !important;
          border-color: none !important;
          border-style: none !important;
      }
      .accordion-button:not(.collapsed) {
          color: #0E0E10;
          background: linear-gradient(90deg, #c3c3c3 -12%, #F4F4F4 60.42%);
          box-shadow: none;
      }
      .accordion-body{
          background: linear-gradient(90deg, #c3c3c3 -12%, #F4F4F4 60.42%);
      }
      .accordion-item:first-of-type .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -12% #F4F4F4 60.42%);
      }
}

@media screen and (min-width: 1233px) and (max-width: 1350px){
    .Ques_main_class {
        width: auto;
        background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
        height: auto;
        padding-top: 3%;
      }
      .Ques_highlight {
        color: #0e0e10;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.16px;
      }
      .Ques_heading {
        color: #0e0e10;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
      }
      .ending_space{
          padding-top: 4%;
      }
      .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -10%, #F4F4F4 60.42%);
          color: #0E0E10;
          font-family: "Poppins", sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.48px;
      border: none !important;
      border-color: none !important;
      border-style: none !important;
      /* background-image: url("./../../Assets/arrow before.png"); */
      }
      .accordion{
          background: linear-gradient(90deg, #c3c3c3 -10%, #F4F4F4 60.42%);
          border: none !important;
          border-color: none !important;
          border-style: none !important;
      }
      .accordion-button:not(.collapsed) {
          color: #0E0E10;
          background: linear-gradient(90deg, #c3c3c3 -10%, #F4F4F4 60.42%);
          box-shadow: none;
      }
      .accordion-body{
          background: linear-gradient(90deg, #c3c3c3 -10%, #F4F4F4 60.42%);
      }
      .accordion-item:first-of-type .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -10% #F4F4F4 60.42%);
      }
}

@media screen and (min-width: 1200px) and (max-width: 1234px){
    .Ques_main_class {
        width: auto;
        background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
        height: auto;
        padding-top: 3%;
      }
      .Ques_highlight {
        color: #0e0e10;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.16px;
      }
      .Ques_heading {
        color: #0e0e10;
        font-family: "Poppins", sans-serif;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
      }
      .ending_space{
          padding-top: 4%;
      }
      .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
          color: #0E0E10;
          font-family: "Poppins", sans-serif;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.48px;
      border: none !important;
      border-color: none !important;
      border-style: none !important;
      /* background-image: url("./../../Assets/arrow before.png"); */
      }
      .accordion{
          background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
          border: none !important;
          border-color: none !important;
          border-style: none !important;
      }
      .accordion-button:not(.collapsed) {
          color: #0E0E10;
          background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
          box-shadow: none;
      }
      .accordion-body{
          background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
      }
      .accordion-item:first-of-type .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -4% #F4F4F4 60.42%);
      }
}

@media screen and (min-width: 768px) and (max-width: 991px){
      .Ques_highlight {
        color: #0e0e10;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.16px;
      }
      .Ques_heading {
        color: #0e0e10;
        font-family: "Poppins", sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
      }
      .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -25%, #F4F4F4 60.42%);
          color: #0E0E10;
          font-family: "Poppins", sans-serif;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.48px;
      border: none !important;
      border-color: none !important;
      border-style: none !important;
      /* background-image: url("./../../Assets/arrow before.png"); */
      }
      .accordion-button{
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
        color: #0E0E10;
        font-family: "Poppins", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    border: none !important;
    border-color: none !important;
    border-style: none !important;
    /* background-image: url("./../../Assets/arrow before.png"); */
    }
    .accordion{
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
        border: none !important;
        border-color: none !important;
        border-style: none !important;
    }
    .accordion-button:not(.collapsed) {
        color: #0E0E10;
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
        box-shadow: none;
    }
    .accordion-body{
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
    }
    .accordion-item:first-of-type .accordion-button{
        background: linear-gradient(90deg, #c3c3c3 -4% #F4F4F4 60.42%);
    }
}

@media screen and (min-width: 576px) and (max-width: 777px){
    .Ques_highlight {
        color: #0e0e10;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.16px;
      }
      .Ques_heading {
        color: #0e0e10;
        font-family: "Poppins", sans-serif;
        font-size: 35px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
      }
      .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -25%, #F4F4F4 60.42%);
          color: #0E0E10;
          font-family: "Poppins", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.48px;
      border: none !important;
      border-color: none !important;
      border-style: none !important;
      /* background-image: url("./../../Assets/arrow before.png"); */
      }
      .accordion-button{
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
        color: #0E0E10;
        font-family: "Poppins", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    border: none !important;
    border-color: none !important;
    border-style: none !important;
    /* background-image: url("./../../Assets/arrow before.png"); */
    }
    .accordion{
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
        border: none !important;
        border-color: none !important;
        border-style: none !important;
    }
    .accordion-button:not(.collapsed) {
        color: #0E0E10;
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
        box-shadow: none;
    }
    .accordion-body{
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
    }
    .accordion-item:first-of-type .accordion-button{
        background: linear-gradient(90deg, #c3c3c3 -4% #F4F4F4 60.42%);
    }
}

@media screen and (min-width: 320px) and (max-width: 575px){
    .Ques_highlight {
        color: #0e0e10;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.16px;
      }
      .Ques_heading {
        color: #0e0e10;
        font-family: "Poppins", sans-serif;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.16px;
        text-align: center;
      }
      .accordion-button{
          background: linear-gradient(90deg, #c3c3c3 -25%, #F4F4F4 60.42%);
          color: #0E0E10;
          font-family: "Poppins", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.48px;
      border: none !important;
      border-color: none !important;
      border-style: none !important;
      /* background-image: url("./../../Assets/arrow before.png"); */
      }
      .accordion-button{
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
        color: #0E0E10;
        font-family: "Poppins", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    border: none !important;
    border-color: none !important;
    border-style: none !important;
    /* background-image: url("./../../Assets/arrow before.png"); */
    }
    .accordion{
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
        border: none !important;
        border-color: none !important;
        border-style: none !important;
    }
    .accordion-button:not(.collapsed) {
        color: #0E0E10;
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
        box-shadow: none;
    }
    .accordion-body{
        background: linear-gradient(90deg, #c3c3c3 -4%, #F4F4F4 60.42%);
    }
    .accordion-item:first-of-type .accordion-button{
        background: linear-gradient(90deg, #c3c3c3 -4% #F4F4F4 60.42%);
    }
}
