.main_div_home_buyer{
    width:100% !important;
    padding: 0% !important;
    margin: 0% !important;
    height:878px;
    background-image: url('./../../Assets/background.png');
    background-size: cover;
}

.homer_buyer_main_heading{
    color: #0E0E10;
text-align: center;
font-family: 'Poppins', sans-serif;
font-size: 94px;
font-style: normal;
font-weight: 800;
line-height: 100%; /* 104px */
letter-spacing: 5.2px;
padding-top: 4%;
}
.home_buyer_description{
    color: #0E0E10;
text-align: center;
font-family: 'Poppins', sans-serif;
font-size: 34px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.home_main_buyer_form{
    width: 94%;
height: 316px;
text-align: center;
border: 1px solid rgba(0, 0, 0, 0.40);
background: rgba(255, 246, 246, 0.20);
backdrop-filter: blur(7.5px);
margin-left: 3%;
padding-top: 3%;
}
.form_heading{
    color: #0E0E10;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.9px;}
.input_first_name{
    width: 100%;
height: 48px;
color: #585858;
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.7px;
background: #FFF;
margin-left:22%;
}
.input_email{
    width: 100%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    background: #FFF;
    margin-left:22%;
    margin-top: 5%;
}
.input_address{
    width: 83%;
height: 48px;
color: #585858;
font-family: 'Poppins', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.7px;
margin-top: 3%;
}
.button_form{
    color: #FFF;
    font-family: 'Poppins', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.7px;
width: 230px;
height: 48px;
border-radius: 10px;
border: 1px solid #0E0E10;
background: #121212;
box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
margin-top: 3%;
}

@media screen and (min-width: 1024px) and (max-width: 1200px){
    .main_div_home_buyer{
        width:100% !important;
        padding: 0% !important;
        margin: 0% !important;
        height:878px;
        background-image: url('./../../Assets/background.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .homer_buyer_main_heading{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    
    font-size: 74px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 104px */
    letter-spacing: 5.2px;
    padding-top: 4%;
    }
    .home_buyer_description{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
    .home_main_buyer_form{
        width: 94%;
    height: 316px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: rgba(255, 246, 246, 0.20);
    backdrop-filter: blur(7.5px);
    margin-left: 3%;
    padding-top: 3%;
    }
    .form_heading{
        color: #0E0E10;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.9px;}
    .input_first_name{
        width: 100%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    background: #FFF;
    margin-left:22%;
    }
    .input_email{
        width: 100%;
        height: 48px;
        color: #585858;
        font-family: 'Poppins', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.7px;
        background: #FFF;
        margin-left:22%;
        margin-top: 5%;
    }
    .input_address{
        width: 83%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 3%;
    }
    .button_form{
        color: #FFF;
        font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    width: 230px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #0E0E10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
    margin-top: 3%;
    }
}

@media screen and (min-width: 993px) and (max-width: 1024px){
    .main_div_home_buyer{
        width:100% !important;
        padding: 0% !important;
        margin: 0% !important;
        height:878px;
        background-image: url('./../../Assets/background.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .homer_buyer_main_heading{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 104px */
    letter-spacing: 5.2px;
    padding-top: 4%;
    }
    .home_buyer_description{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
    .home_main_buyer_form{
        width: 94%;
    height: 316px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: rgba(255, 246, 246, 0.20);
    backdrop-filter: blur(7.5px);
    margin-left: 3%;
    padding-top: 3%;
    }
    .form_heading{
        color: #0E0E10;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.9px;}
    .input_first_name{
        width: 100%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    background: #FFF;
    margin-left:22%;
    }
    .input_email{
        width: 100%;
        height: 48px;
        color: #585858;
        font-family: 'Poppins', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.7px;
        background: #FFF;
        margin-left:22%;
        margin-top: 5%;
    }
    .input_address{
        width: 83%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 3%;
    }
    .button_form{
        color: #FFF;
        font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    width: 230px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #0E0E10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
    margin-top: 3%;
    }
}

@media screen and (max-width: 992px){
    .main_div_home_buyer{
        width:100% !important;
        padding: 0% !important;
        margin: 0% !important;
        height:878px;
        background-image: url('./../../Assets/background.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .homer_buyer_main_heading{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 104px */
    letter-spacing: 5.2px;
    padding-top: 4%;
    }
    .home_buyer_description{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
    .home_main_buyer_form{
        width: 94%;
    height: 316px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: rgba(255, 246, 246, 0.20);
    backdrop-filter: blur(7.5px);
    margin-left: 3%;
    padding-top: 3%;
    }
    .form_heading{
        color: #0E0E10;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.9px;}
    .input_first_name{
        width: 100%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    background: #FFF;
    margin-left:22%;
    }
    .input_email{
        width: 100%;
        height: 48px;
        color: #585858;
        font-family: 'Poppins', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.7px;
        background: #FFF;
        margin-left:22%;
        margin-top: 5%;
    }
    .input_address{
        width: 83%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 3%;
    }
    .button_form{
        color: #FFF;
        font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    width: 230px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #0E0E10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
    margin-top: 3%;
    }
}

@media screen and (min-width: 769px) and (max-width: 991px){
    .main_div_home_buyer{
        width:100% !important;
        padding: 0% !important;
        margin: 0% !important;
        height:878px;
        background-image: url('./../../Assets/background.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .homer_buyer_main_heading{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    
    font-size: 55px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 104px */
    letter-spacing: 5.2px;
    padding-top: 4%;
    }
    .home_buyer_description{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
    .home_main_buyer_form{
        width: 95%;
    height: 336px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: rgba(255, 246, 246, 0.20);
    backdrop-filter: blur(7.5px);
    margin-left: 3%;
    padding-top: 3%;
    }
    .form_heading{
        color: #0E0E10;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.9px;}
    .input_first_name{
        width: 100%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    background: #FFF;
    margin-left:25%;
    }
    .input_email{
        width: 100%;
        height: 48px;
        color: #585858;
        font-family: 'Poppins', sans-serif;
        font-size: 6px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.7px;
        background: #FFF;
        margin-left:25%;
        margin-top: 5%;
    }
    .input_address{
        width: 83%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 3%;
    }
    .button_form{
        color: #FFF;
        font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    width: 150px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #0E0E10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
    margin-top: 3%;
    }
}

@media screen and (min-width: 664px) and (max-width: 769px){
    .main_div_home_buyer{
        width:100% !important;
        padding: 0% !important;
        margin: 0% !important;
        height:808px;
        background-image: url('./../../Assets/background.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .homer_buyer_main_heading{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 104px */
    letter-spacing: 5.2px;
    padding-top: 4%;
    }
    .home_buyer_description{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
    .home_main_buyer_form{
        width: 95%;
    height: 306px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: rgba(255, 246, 246, 0.20);
    backdrop-filter: blur(7.5px);
    margin-left: 3%;
    padding-top: 3%;
    }
    .form_heading{
        color: #0E0E10;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.9px;}
    .input_first_name{
        width: 100%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    background: #FFF;
    margin-left:25%;
    }
    .input_email{
        width: 100%;
        height: 48px;
        color: #585858;
        font-family: 'Poppins', sans-serif;
        font-size: 6px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.7px;
        background: #FFF;
        margin-left:25%;
        margin-top: 5%;
    }
    .input_address{
        width: 83%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 3%;
    }
    .button_form{
        color: #FFF;
        font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    width: 150px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #0E0E10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
    margin-top: 3%;
    }
}

@media screen and (min-width: 484px) and (max-width: 664px){
.main_div_home_buyer{
    width:100% !important;
    padding: 0% !important;
    margin: 0% !important;
    height:730px;
    background-image: url('./../../Assets/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.homer_buyer_main_heading{
    color: #0E0E10;
text-align: center;
font-family: 'Poppins', sans-serif;

font-size: 30px;
font-style: normal;
font-weight: 800;
line-height: 100%; /* 104px */
letter-spacing: 5.2px;
padding-top: 4%;
}
.home_buyer_description{
    color: #0E0E10;
text-align: center;
font-family: 'Poppins', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.home_main_buyer_form{
    width: 150%;
height: 306px;
text-align: center;
border: 1px solid rgba(0, 0, 0, 0.40);
background: rgba(255, 246, 246, 0.20);
backdrop-filter: blur(7.5px);
margin-left: -20%;
padding-top: 3%;
}
.form_heading{
    color: #0E0E10;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.9px;}
.input_first_name{
    width: 100%;
height: 48px;
color: #585858;
font-family: 'Poppins', sans-serif;
font-size: 7px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.7px;
background: #FFF;
margin-left:25%;
}
.input_email{
    width: 100%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 6px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    background: #FFF;
    margin-left:25%;
    margin-top: 5%;
}
.input_address{
    width: 83%;
height: 48px;
color: #585858;
font-family: 'Poppins', sans-serif;
font-size: 7px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.7px;
margin-top: 3%;
}
.button_form{
    color: #FFF;
    font-family: 'Poppins', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.7px;
width: 150px;
height: 48px;
border-radius: 10px;
border: 1px solid #0E0E10;
background: #121212;
box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
margin-top: 3%;
}
}

@media screen and (min-width: 441px) and (max-width: 484px){
    .main_div_home_buyer{
        width:100% !important;
        padding: 0% !important;
        margin: 0% !important;
        height:730px;
        background-image: url('./../../Assets/background.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .homer_buyer_main_heading{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 104px */
    letter-spacing: 5.2px;
    padding-top: 4%;
    }
    .home_buyer_description{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
    .home_main_buyer_form{
        width: 150%;
    height: 306px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: rgba(255, 246, 246, 0.20);
    backdrop-filter: blur(7.5px);
    margin-left: -20%;
    padding-top: 3%;
    }
    .form_heading{
        color: #0E0E10;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.9px;}
    .input_first_name{
        width: 100%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    background: #FFF;
    margin-left:25%;
    }
    .input_email{
        width: 100%;
        height: 48px;
        color: #585858;
        font-family: 'Poppins', sans-serif;
        font-size: 6px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.7px;
        background: #FFF;
        margin-left:25%;
        margin-top: 5%;
    }
    .input_address{
        width: 83%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 3%;
    }
    .button_form{
        color: #FFF;
        font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    width: 150px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #0E0E10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
    margin-top: 3%;
    }
}

@media screen and (min-width: 340px) and (max-width: 441px){
    .main_div_home_buyer{
        width:100% !important;
        padding: 0% !important;
        margin: 0% !important;
        height:600px;
        background-image: url('./../../Assets/background.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .homer_buyer_main_heading{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 104px */
    letter-spacing: 5.2px;
    padding-top: 4%;
    }
    .home_buyer_description{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
    .home_main_buyer_form{
        width: 200%;
    height: 306px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: rgba(255, 246, 246, 0.20);
    backdrop-filter: blur(7.5px);
    margin-left: -50%;
    padding-top: 3%;
    }
    .form_heading{
        color: #0E0E10;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.9px;}
    .input_first_name{
        width: 100%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    background: #FFF;
    margin-left:25%;
    }
    .input_email{
        width: 100%;
        height: 48px;
        color: #585858;
        font-family: 'Poppins', sans-serif;
        font-size: 6px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.7px;
        background: #FFF;
        margin-left:25%;
        margin-top: 5%;
    }
    .input_address{
        width: 83%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 3%;
    }
    .button_form{
        color: #FFF;
        font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    width: 150px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #0E0E10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
    margin-top: 3%;
    }
}

@media screen and (min-width: 280px) and (max-width: 340px){
    .main_div_home_buyer{
        width:100% !important;
        padding: 0% !important;
        margin: 0% !important;
        height:600px;
        background-image: url('./../../Assets/background.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .homer_buyer_main_heading{
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 104px */
    letter-spacing: 5.2px;
    padding-top: 4%;
    }
    .home_buyer_description{
        width:200%;
        margin-left: -50%;
        color: #0E0E10;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
    .home_main_buyer_form{
        width: 250%;
    height: 306px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.40);
    background: rgba(255, 246, 246, 0.20);
    backdrop-filter: blur(7.5px);
    margin-left: -80%;
    padding-top: 3%;
    }
    .form_heading{
        padding-top: 3%;
        color: #0E0E10;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.9px;}
    .input_first_name{
        width: 100%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    background: #FFF;
    margin-left:25%;
    }
    .input_email{
        width: 100%;
        height: 48px;
        color: #585858;
        font-family: 'Poppins', sans-serif;
        font-size: 5px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.7px;
        background: #FFF;
        margin-left:25%;
        margin-top: 5%;
    }
    .input_address{
        width: 83%;
    height: 48px;
    color: #585858;
    font-family: 'Poppins', sans-serif;
    font-size: 5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 3%;
    }
    .button_form{
        color: #FFF;
        font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    width: 120px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #0E0E10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.10);
    margin-top: 3%;
    }
}