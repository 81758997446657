.main_background_img {
  width: 100%x;
  height: 486px;
  /* top: 2819px; */
  /* left: -1px; */
  background-image: url("./../../Assets/schedule_img.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.Schedule_main_heading {
  color: #0e0e10;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 58px */
  letter-spacing: 1.16px;
  padding-top: 8%;
}
.today_text {
  color: #0e0e10;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 58px */
  letter-spacing: 1.16px;
}
.consultation_text {
  color: #0e0e10;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.16px;
}
.hours {
  color: #0e0e10;
  font-family: "Poppins", sans-serif;
  font-size: 29px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.68px;
  padding-top: 1%;
}
.schedule_button {
  width: 230px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #0e0e10;
  background: #121212;
  box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
  margin-top: 3%;
  /* padding-top: 4%; */
}
.schedule_call_us {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.68px;
  padding-top: 3%;
}
.number {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.68px;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .main_background_img {
    width: 100%x;
    height: 430px;
    /* top: 2819px; */
    /* left: -1px; */
    background-image: url("./../../Assets/schedule_img.png");
  }
  .Schedule_main_heading {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
    padding-top: 8%;
  }
  .today_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
  }
  .consultation_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1.16px;
  }
  .hours {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 1%;
  }
  .schedule_button {
    width: 230px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #0e0e10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 4%;
    /* padding-top: 4%; */
  }
  .schedule_call_us {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 7%;
  }
  .number {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.68px;
  }
}

@media screen and (min-width: 993px) and (max-width: 1024px) {
  .main_background_img {
    width: 100%x;
    height: 420px;
    /* top: 2819px; */
    /* left: -1px; */
    background-image: url("./../../Assets/schedule_img.png");
  }
  .Schedule_main_heading {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
    padding-top: 8%;
  }
  .today_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
  }
  .consultation_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1.16px;
  }
  .hours {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 1%;
  }
  .schedule_button {
    width: 230px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #0e0e10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 4%;
    /* padding-top: 4%; */
  }
  .schedule_call_us {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 7%;
  }
  .number {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.68px;
  }
}

@media screen and (max-width: 992px) {
  .main_background_img {
    width: 100%x;
    height: 486px;
    /* top: 2819px; */
    /* left: -1px; */
    background-image: url("./../../Assets/schedule_img.png");
  }
  .Schedule_main_heading {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
    padding-top: 8%;
  }
  .today_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
  }
  .consultation_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1.16px;
  }
  .hours {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 1%;
  }
  .schedule_button {
    width: 230px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #0e0e10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 4%;
    /* padding-top: 4%; */
  }
  .schedule_call_us {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 7%;
  }
  .number {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.68px;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .main_background_img {
    width: 100%;
    height: 380px;
    /* top: 2819px; */
    /* left: -1px; */
    background-image: url("./../../Assets/schedule_img.png");
  }
  .Schedule_main_heading {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 37px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
    padding-top: 8%;
  }
  .today_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
  }
  .consultation_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1.16px;
  }
  .hours {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 1%;
  }
  .schedule_button {
    width: 230px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #0e0e10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 6%;
    /* padding-top: 4%; */
  }
  .schedule_call_us {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 10%;
  }
  .number {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.68px;
  }
}

@media screen and (min-width: 664px) and (max-width: 769px) {
  .main_background_img {
    width: 100%;
    height: 390px;
    /* top: 2819px; */
    /* left: -1px; */
    background-image: url("./../../Assets/schedule_img.png");
  }
  .Schedule_main_heading {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 33px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
    padding-top: 8%;
  }
  .today_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
  }
  .consultation_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1.16px;
  }
  .hours {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 1%;
  }
  .schedule_button {
    width: 230px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #0e0e10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 6%;
    /* padding-top: 4%; */
  }
  .schedule_call_us {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 10%;
  }
  .number {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.68px;
  }
}

@media screen and (min-width: 484px) and (max-width: 664px) {
  .main_background_img {
    width: 100%;
    height: 330px;
    /* object-fit: contain; */
    /* top: 2819px; */
    /* left: -1px; */
    background-image: url("./../../Assets/schedule_img.png");
  }
  .Schedule_main_heading {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
    padding-top: 8%;
  }
  .today_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
  }
  .consultation_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1.16px;
  }
  .hours {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 1%;
  }
  .schedule_button {
    width: 200px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #0e0e10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 6%;
    /* padding-top: 4%; */
  }
  .schedule_call_us {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 7%;
  }
  .number {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 10%;
  }
}

@media screen and (min-width: 441px) and (max-width: 484px) {
  .main_background_img {
    width: 100%;
    height: 290px;
    /* object-fit: contain; */
    /* top: 2819px; */
    /* left: -1px; */
    background-image: url("./../../Assets/schedule_img.png");
  }
  .Schedule_main_heading {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
    padding-top: 8%;
  }
  .today_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
  }
  .consultation_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1.16px;
  }
  .hours {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 1%;
  }
  .schedule_button {
    width: 180px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #0e0e10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 6%;
    /* padding-top: 4%; */
  }
  .schedule_call_us {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 10%;
  }
  .number {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 10%;
  }
}

@media screen and (min-width: 340px) and (max-width: 441px) {
  .main_background_img {
    width: 100%;
    height: 300px;
    /* object-fit: contain; */
    /* top: 2819px; */
    /* left: -1px; */
    background-image: url("./../../Assets/schedule_img.png");
  }
  .Schedule_main_heading {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
    padding-top: 14%;
  }
  .today_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
  }
  .consultation_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1.16px;
  }
  .hours {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 1%;
  }
  .schedule_button {
    width: 150px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #0e0e10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 6%;
    /* padding-top: 4%; */
  }
  .schedule_call_us {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 10%;
  }
  .number {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 10%;
  }
}

@media screen and (min-width: 280px) and (max-width: 340px) {
  .main_background_img {
    width: 100%;
    height: 250px;
    /* object-fit: contain; */
    /* top: 2819px; */
    /* left: -1px; */
    background-image: url("./../../Assets/schedule_img.png");
  }
  .Schedule_main_heading {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
    padding-top: 14%;
  }
  .today_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 58px */
    letter-spacing: 1.16px;
  }
  .consultation_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1.16px;
  }
  .hours {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 1%;
  }
  .schedule_button {
    width: 130px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #0e0e10;
    background: #121212;
    box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.1);
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
    margin-top: 6%;
    /* padding-top: 4%; */
  }
  .schedule_call_us {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 10%;
  }
  .number {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.68px;
    padding-top: 10%;
  }
}
