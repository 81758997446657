.Selling_main_class {
  background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
  width: auto;
  height: 920px;
}
.SellingWith {
  color: #0e0e10;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 119%; /* 70.113px */
  margin-top: 12%;
}
.vs {
  color: #0e0e10;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}
.us {
  color: #0e0e10;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 119%;
}
.space_margin {
  margin-top: 3%;
}
.Commision {
  width: auto;
  height: 62px;
  background-color: black;
}
.comm_text {
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  padding: 7%;
}
.pay_text{
    color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  padding: 4%;
}
.white_backg {
  width: auto;
  height: 124px;
  background-color: #fff;
}
.true_img {
  width: 30px;
  height: 30px;
  margin-top: 33%;
  margin-left: 15%;
  background-size: contain;
  background-image: url("./../../Assets/true.png");
}
.false_img {
  width: 30px;
  height: 30px;
  margin-top: 30%;
  margin-left: 15%;
  background-size: contain;
  background-image: url("./../../Assets/false.png");
}
.true_para {
  color: #0b0b0c;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  margin-top: 12%;
  line-height: normal;
}
.false_para {
  color: #0b0b0c;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  /* margin-left: -5%; */
  margin-top: 7%;
  line-height: normal;
}
.false_parag{
    color: #0b0b0c;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  /* margin-left: -5%; */
  margin-top: 0.5%;
  line-height: normal;
}
.row_margin{
    margin-left: 1.2%;
}

@media screen and (min-width: 1500px) and (max-width: 1784px){
  .Selling_main_class {
    background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
    width: auto;
    height: 860px;
  }
  .SellingWith {
    color: #0e0e10;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 70.113px */
    margin-top: 12%;
  }
  .vs {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .us {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
  }
  .space_margin {
    margin-top: 3%;
  }
  .Commision {
    width: auto;
    height: 62px;
    background-color: black;
  }
  .comm_text {
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 7%;
  }
  .pay_text{
      color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 4%;
  }
  .white_backg {
    width: auto;
    height: 100px;
    background-color: #fff;
  }
  .true_img {
    width: 20px;
    height: 24px;
    margin-top: 33%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/true.png");
  }
  .false_img {
    width: 20px;
    height: 24px;
    margin-top: 35%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/false.png");
  }
  .true_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 46%;
    font-style: normal;
    font-weight: 300;
    margin-top: 12%;
    line-height: normal;
  }
  .false_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 46%;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 9%;
    line-height: normal;
  }
  .false_parag{
      color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 0.5%;
    line-height: normal;
  }
  .row_margin{
      margin-left: 1.2%;
  }
}

@media screen and (min-width: 1240px) and (max-width: 1500px){
  .Selling_main_class {
    background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
    width: auto;
    height: 860px;
  }
  .SellingWith {
    color: #0e0e10;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 70.113px */
    margin-top: 12%;
  }
  .vs {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .us {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
  }
  .space_margin {
    margin-top: 3%;
  }
  .Commision {
    width: auto;
    height: 62px;
    background-color: black;
  }
  .comm_text {
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 7%;
  }
  .pay_text{
      color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 4%;
  }
  .white_backg {
    width: auto;
    height: 100px;
    background-color: #fff;
  }
  .true_img {
    width: 20px;
    height: 24px;
    margin-top: 33%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/true.png");
  }
  .false_img {
    width: 20px;
    height: 24px;
    margin-top: 50%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/false.png");
  }
  .true_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 46%;
    font-style: normal;
    font-weight: 300;
    margin-top: 12%;
    line-height: normal;
  }
  .false_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 46%;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 9%;
    line-height: normal;
  }
  .false_parag{
      color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 0.5%;
    line-height: normal;
  }
  .row_margin{
      margin-left: 1.2%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1240px){
  .Selling_main_class {
    background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
    width: auto;
    height: 620px;
  }
  .SellingWith {
    color: #0e0e10;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 70.113px */
    margin-top: 12%;
  }
  .vs {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .us {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
  }
  .space_margin {
    margin-top: 3%;
  }
  .Commision {
    width: auto;
    height: 52px;
    background-color: black;
  }
  .comm_text {
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 7%;
  }
  .pay_text{
      color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 4%;
  }
  .white_backg {
    width: auto;
    height: 80px;
    background-color: #fff;
  }
  .true_img {
    width: 30px;
    height: 24px;
    margin-top: 33%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/true.png");
  }
  .false_img {
    width: 30px;
    height: 24px;
    margin-top: 50%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/false.png");
  }
  .true_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 30%;
    font-style: normal;
    font-weight: 300;
    margin-top: 12%;
    line-height: normal;
  }
  .false_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 40%;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 11%;
    line-height: normal;
  }
  .false_parag{
      color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 0.5%;
    line-height: normal;
  }
  .row_margin{
      margin-left: 1.2%;
  }
}

@media screen and (min-width: 915px) and (max-width: 991px){
  .Selling_main_class {
    background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
    width: auto;
    height: 620px;
  }
  /* .row_space{
    padding-top: 3%;;
  } */
  .SellingWith {
    color: #0e0e10;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 70.113px */
    margin-top: 8%;
  }
  .vs {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .us {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
  }
  .space_margin {
    margin-top: 3%;
  }
  .Commision {
    width: auto;
    height: 42px;
    background-color: black;
  }
  .comm_text {
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 7%;
  }
  .pay_text{
      color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 4%;
  }
  .white_backg {
    width: auto;
    height: 70px;
    background-color: #fff;
  }
  .true_img {
    width: 15px;
    height: 24px;
    margin-top: 60%;
    /* margin-left: 15%; */
    background-size: contain;
    background-image: url("./../../Assets/true.png");
  }
  .false_img {
    width: 30px;
    height: 24px;
    margin-top: 60%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/false.png");
  }
  .true_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 25%;
    font-style: normal;
    font-weight: 300;
    margin-top: 15%;
    line-height: normal;
  }
  .false_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 30%;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 11%;
    line-height: normal;
  }
  .false_parag{
      color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 0.5%;
    line-height: normal;
  }
  .row_margin{
      margin-left: 1.2%;
  }
}

@media screen and (min-width: 768px) and (max-width: 915px){
  .Selling_main_class {
    background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
    width: auto;
    height: 560px;
  }
  /* .row_space{
    padding-top: 3%;;
  } */
  .SellingWith {
    color: #0e0e10;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 70.113px */
    margin-top: 8%;
  }
  .vs {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .us {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
  }
  .space_margin {
    margin-top: 3%;
  }
  .Commision {
    width: auto;
    height: 42px;
    background-color: black;
  }
  .comm_text {
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 7%;
  }
  .pay_text{
      color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 4%;
  }
  .white_backg {
    width: auto;
    height: 70px;
    background-color: #fff;
  }
  .true_img {
    width: 20px;
    height: 24px;
    margin-top: 100%;
    /* margin-left: 15%; */
    background-size: contain;
    background-image: url("./../../Assets/true.png");
  }
  .false_img {
    width: 20px;
    height: 24px;
    margin-top: 100%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/false.png");
  }
  .true_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 25%;
    font-style: normal;
    font-weight: 300;
    margin-top: 15%;
    line-height: normal;
  }
  .false_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 30%;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 18%;
    line-height: normal;
  }
  .false_parag{
      color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 0.5%;
    line-height: normal;
  }
  .row_margin{
      margin-left: 1.2%;
  }
}

@media screen and (min-width: 562px) and (max-width: 767px){
  .Selling_main_class {
    background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
    width: auto;
    height: 1100px;
  }
  .SellingWith {
    color: #0e0e10;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 70.113px */
    margin-top: 12%;
  }
  .vs {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .us {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
  }
  .space_margin {
    margin-top: 3%;
  }
  .Commision {
    width: auto;
    height: 62px;
    background-color: black;
  }
  .comm_text {
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 7%;
  }
  .pay_text{
      color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 4%;
  }
  .white_backg {
    width: auto;
    height: 100px;
    background-color: #fff;
  }
  .true_img {
    width: 30px;
    height: 30px;
    margin-top: 20%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/true.png");
  }
  .false_img {
    width: 30px;
    height: 30px;
    margin-top: 30%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/false.png");
  }
  .true_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 300;
    margin-top: 12%;
    line-height: normal;
  }
  .false_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 7px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 7%;
    line-height: normal;
  }
  .false_parag{
      color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 0.5%;
    line-height: normal;
  }
  .row_margin{
      margin-left: 1.2%;
  }
  .row_margin2{
    padding-top: 5%;
    margin-left: 9%;
}
.row_margin3{
  padding-top: 5%;
}
}

@media screen and (min-width: 537px) and (max-width: 561px){
  .Selling_main_class {
    background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
    width: auto;
    height: 1024px;
  }
  .SellingWith {
    color: #0e0e10;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 70.113px */
    margin-top: 12%;
  }
  .vs {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .us {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
  }
  .space_margin {
    margin-top: 3%;
  }
  .Commision {
    width: auto;
    height: 62px;
    background-color: black;
  }
  .comm_text {
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 7%;
  }
  .pay_text{
      color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 4%;
  }
  .white_backg {
    width: auto;
    height: 80px;
    background-color: #fff;
  }
  .true_img {
    width: 30px;
    height: 29px;
    margin-top: 20%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/true.png");
  }
  .false_img {
    width: 30px;
    height: 29px;
    margin-top: 30%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/false.png");
  }
  .true_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 6px;
    font-style: normal;
    font-weight: 300;
    margin-top: 12%;
    line-height: normal;
  }
  .false_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 6px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 10%;
    line-height: normal;
  }
  .false_parag{
      color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 0.5%;
    line-height: normal;
  }
  .row_margin{
      margin-left: 1.2%;
  }
  .row_margin2{
    padding-top: 5%;
    margin-left: 9%;
}
.row_margin3{
  padding-top: 5%;
}
}

@media screen and (min-width: 444px) and (max-width: 537px){
  .Selling_main_class {
    background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
    width: auto;
    height: 1000px;
  }
  .SellingWith {
    color: #0e0e10;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 70.113px */
    margin-top: 12%;
  }
  .vs {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .us {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
  }
  .space_margin {
    margin-top: 3%;
  }
  .Commision {
    width: auto;
    height: 62px;
    background-color: black;
  }
  .comm_text {
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 7%;
  }
  .pay_text{
      color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 4%;
  }
  .white_backg {
    width: auto;
    height: 80px;
    background-color: #fff;
  }
  .true_img {
    width: 30px;
    height: 25px;
    margin-top: 20%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/true.png");
  }
  .false_img {
    width: 30px;
    height: 25px;
    margin-top: 30%;
    margin-left: 15%;
    background-size: contain;
    background-image: url("./../../Assets/false.png");
  }
  .true_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 6px;
    font-style: normal;
    font-weight: 300;
    margin-top: 12%;
    line-height: normal;
  }
  .false_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 6px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 7%;
    line-height: normal;
  }
  .false_parag{
      color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 0.5%;
    line-height: normal;
  }
  .row_margin{
      margin-left: 1.2%;
  }
  .row_margin2{
    padding-top: 5%;
    margin-left: 9%;
}
.row_margin3{
  padding-top: 5%;
}
}

@media screen and (min-width: 320px) and (max-width: 443px){
  .Selling_main_class {
    background-image: linear-gradient(90deg, #c3c3c3 0%, #f4f4f4 60.42%);
    width: auto;
    height: 710px;
  }
  .SellingWith {
    color: #0e0e10;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 119%; /* 70.113px */
    margin-top: 12%;
  }
  .vs {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .us {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 119%;
  }
  .space_margin {
    margin-top: 3%;
  }
  .Commision {
    width: auto;
    height: 40px;
    background-color: black;
  }
  .comm_text {
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 7%;
  }
  .pay_text{
      color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    padding: 4%;
  }
  .white_backg {
    width: auto;
    height: 60px;
    background-color: #fff;
  }
  .true_img {
    width: 20px;
    height: 20px;
    margin-top: 30%;
    margin-left: 15%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./../../Assets/true.png");
  }
  .false_img {
    width: 20px;
    height: 20px;
    margin-top: 40%;
    margin-left: 15%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./../../Assets/false.png");
  }
  .true_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 5px;
    font-style: normal;
    font-weight: 300;
    margin-top: 12%;
    line-height: normal;
  }
  .false_para {
    color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 4px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 5%;
    line-height: normal;
  }
  .false_parag{
      color: #0b0b0c;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    /* margin-left: -5%; */
    margin-top: 0.5%;
    line-height: normal;
  }
  .row_margin{
      margin-left: 1.2%;
  }
  .row_margin2{
    padding-top: 5%;
    margin-left: 9%;
}
.row_margin3{
  padding-top: 5%;
}
}

