.main_class {
  width: auto;
  height: auto;
  background-color: black;
}
.end_space{
  margin-top: 2%;
}
.selling {
  width: 62%;
  height: 80%;
  margin-top: 15%;
}
.First_text {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  /* margin-top: 5%; */
  /* /* padding: 0%;
  margin: 0%; */
  /* display: flex;
  flex-direction: column;  */
}
.center_text {
  margin-top: 2%;
  width: 520px;
  height: 469px;
  background: #ffffff;
  position: relative;
  z-index: 1;
}
.first_img {
  width: 90px;
  height: 98px;
  margin-left: -28px;
  margin-top: 43px;
  background-size: contain;
  background-image: url("./../../Assets/Group 18.png");
}
.second_img {
  width: 86px;
  height: 98px;
  margin-left: -28px;
  margin-top: 36px;
  background-size: contain;
  background-image: url("./../../Assets/Group 7.png");
}
.third_img {
  width: 86px;
  height: 94px;
  margin-left: -28px;
  margin-top: 41px;
  background-size: contain;
  background-image: url("./../../Assets/Group 19.png");
}
.client_text {
  color: #0e0e10;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;
  padding-top: 15%;
}
.client_para {
  color: #5f605d;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.selling_img {
  width: 496px;
  height: 390px;
  margin-left: -1%;
  margin-top: 4%;
  background-image: url("./../../Assets/SeelingHome.png");
  background-size: contain;
  /* position: relative;
  z-index: 1; */
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
}
@media screen and (min-width: 1349px) and (max-width: 1712px){
  .selling {
    width: 70%;
    height: 80%;
    margin-top: 15%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 85px;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 420px;
    height: 420px;
    background: #ffffff;
    position: relative;
    z-index: 1;
  }
  .first_img {
    width: 84px;
    height: 90px;
    margin-left: -28px;
    margin-top: 38px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 80px;
    height: 90px;
    margin-left: -28px;
    margin-top: 28px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 80px;
    height: 87px;
    margin-left: -28px;
    margin-top: 36px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 380px;
    height: 375px;
    margin-left: -1%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: contain;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 1244px) and (max-width: 1349px){
  .selling {
    width: 70%;
    height: 80%;
    margin-top: 15%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 85px;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 420px;
    height: 420px;
    background: #ffffff;
    position: relative;
    z-index: 1;
  }
  .first_img {
    width: 84px;
    height: 90px;
    margin-left: -28px;
    margin-top: 38px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 80px;
    height: 90px;
    margin-left: -28px;
    margin-top: 28px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 80px;
    height: 87px;
    margin-left: -28px;
    margin-top: 36px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 380px;
    height: 375px;
    margin-left: -6%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: contain;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 1200px) and (max-width: 1244px){
  .selling {
    width: 78%;
    height: 80%;
    margin-top: 15%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 85px;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 420px;
    height: 420px;
    background: #ffffff;
    position: relative;
    z-index: 1;
  }
  .first_img {
    width: 84px;
    height: 90px;
    margin-left: -28px;
    margin-top: 38px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 80px;
    height: 90px;
    margin-left: -28px;
    margin-top: 28px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 80px;
    height: 87px;
    margin-left: -28px;
    margin-top: 36px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 380px;
    height: 375px;
    margin-left: -9%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: contain;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 1165px) and (max-width: 1200px){
  .selling {
    width: 82%;
    height: 80%;
    margin-top: 15%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 85px;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 420px;
    height: 420px;
    background: #ffffff;
    position: relative;
    z-index: 1;
  }
  .first_img {
    width: 84px;
    height: 90px;
    margin-left: -28px;
    margin-top: 38px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 80px;
    height: 90px;
    margin-left: -28px;
    margin-top: 28px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 80px;
    height: 87px;
    margin-left: -28px;
    margin-top: 36px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 380px;
    height: 375px;
    margin-left: -11%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: cover;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 1024px) and (max-width: 1165px){
  .selling {
    width: 82%;
    height: 80%;
    margin-top: 15%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 85px;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 390px;
    height: 420px;
    background: #ffffff;
    position: relative;
    z-index: 1;
  }
  .first_img {
    width: 84px;
    height: 90px;
    margin-left: -28px;
    margin-top: 38px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 80px;
    height: 90px;
    margin-left: -28px;
    margin-top: 28px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 80px;
    height: 87px;
    margin-left: -28px;
    margin-top: 36px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 380px;
    height: 375px;
    margin-left: -18%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: cover;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 993px) and (max-width: 1024px){
  .selling {
    width: 85%;
    height: 80%;
    margin-top: 15%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 85px;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 390px;
    height: 420px;
    background: #ffffff;
    position: relative;
    z-index: 1;
  }
  .first_img {
    width: 84px;
    height: 90px;
    margin-left: -28px;
    margin-top: 38px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 80px;
    height: 90px;
    margin-left: -28px;
    margin-top: 28px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 80px;
    height: 87px;
    margin-left: -28px;
    margin-top: 36px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 380px;
    height: 375px;
    margin-left: -20%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: cover;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (max-width: 992px){
  .selling {
    width: 85%;
    height: 80%;
    margin-top: 15%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 85px;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 390px;
    height: 420px;
    background: #ffffff;
    position: relative;
    z-index: 1;
  }
  .first_img {
    width: 84px;
    height: 90px;
    margin-left: -28px;
    margin-top: 38px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 80px;
    height: 90px;
    margin-left: -28px;
    margin-top: 28px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 80px;
    height: 87px;
    margin-left: -28px;
    margin-top: 36px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 380px;
    height: 375px;
    margin-left: -20%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: cover;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 900px) and (max-width: 991px){
.selling {
    width: 100% ;
    height: 80%;
    margin-top: 5%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 390px;
    height: 420px;
    background: #ffffff;
    position: relative;
    z-index: 1;
    margin-left: 20%;
  }
  .first_img {
    width: 84px;
    height: 90px;
    margin-left: -28px;
    margin-top: 38px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 80px;
    height: 90px;
    margin-left: -28px;
    margin-top: 28px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 80px;
    height: 87px;
    margin-left: -28px;
    margin-top: 36px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 380px;
    height: 375px;
    margin-left: -15%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: cover;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 886px) and (max-width: 991px){
  .selling {
      width: 100% ;
      height: 80%;
      margin-top: 5%;
    }
    .First_text {
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: 70px;
      text-align: center;
      /* margin-top: 5%; */
      /* /* padding: 0%;
      margin: 0%; */
      /* display: flex;
      flex-direction: column;  */
    }
    .center_text {
      margin-top: 2%;
      width: 390px;
      height: 420px;
      background: #ffffff;
      position: relative;
      z-index: 1;
      margin-left: 17%;
    }
    .first_img {
      width: 84px;
      height: 90px;
      margin-left: -28px;
      margin-top: 38px;
      background-size: contain;
      background-image: url("./../../Assets/Group 18.png");
    }
    .second_img {
      width: 80px;
      height: 90px;
      margin-left: -28px;
      margin-top: 28px;
      background-size: contain;
      background-image: url("./../../Assets/Group 7.png");
    }
    .third_img {
      width: 80px;
      height: 87px;
      margin-left: -28px;
      margin-top: 36px;
      background-size: contain;
      background-image: url("./../../Assets/Group 19.png");
    }
    .client_text {
      color: #0e0e10;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1.2px;
      padding-top: 15%;
    }
    .client_para {
      color: #5f605d;
      font-family: "Poppins", sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .selling_img {
      width: 380px;
      height: 375px;
      margin-left: -15%;
      margin-top: 4%;
      background-image: url("./../../Assets/SeelingHome.png");
      background-size: cover;
      /* position: relative;
      z-index: 1; */
      background-repeat: no-repeat;
      /* background-size: 100% 100%; */
    }
  }

  @media screen and (min-width: 768px) and (max-width: 886px){
    .selling {
        width: 100% ;
        height: 80%;
        margin-top: 5%;
      }
      .First_text {
        color: #fff;
        font-family: "Poppins", sans-serif;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 70px;
        text-align: center;
        /* margin-top: 5%; */
        /* /* padding: 0%;
        margin: 0%; */
        /* display: flex;
        flex-direction: column;  */
      }
      .center_text {
        margin-top: 2%;
        width: 390px;
        height: 420px;
        background: #ffffff;
        position: relative;
        z-index: 1;
        margin-left: 17%;
      }
      .first_img {
        width: 84px;
        height: 90px;
        margin-left: -28px;
        margin-top: 38px;
        background-size: contain;
        background-image: url("./../../Assets/Group 18.png");
      }
      .second_img {
        width: 80px;
        height: 90px;
        margin-left: -28px;
        margin-top: 28px;
        background-size: contain;
        background-image: url("./../../Assets/Group 7.png");
      }
      .third_img {
        width: 80px;
        height: 87px;
        margin-left: -28px;
        margin-top: 36px;
        background-size: contain;
        background-image: url("./../../Assets/Group 19.png");
      }
      .client_text {
        color: #0e0e10;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.2px;
        padding-top: 15%;
      }
      .client_para {
        color: #5f605d;
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .selling_img {
        width: 380px;
        height: 375px;
        margin-left: -21%;
        margin-top: 4%;
        background-image: url("./../../Assets/SeelingHome.png");
        background-size: cover;
        /* position: relative;
        z-index: 1; */
        background-repeat: no-repeat;
        /* background-size: 100% 100%; */
      }
    }

@media screen and (min-width: 700px) and (max-width: 768px){
  .selling {
    width: 100% ;
    height: 80%;
    margin-top: 5%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 380px;
    height: 420px;
    background: #ffffff;
    position: relative;
    z-index: 1;
    margin-left: 15%;
  }
  .first_img {
    width: 84px;
    height: 90px;
    margin-left: -28px;
    margin-top: 38px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 80px;
    height: 90px;
    margin-left: -28px;
    margin-top: 28px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 80px;
    height: 87px;
    margin-left: -28px;
    margin-top: 36px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 380px;
    height: 375px;
    margin-left: -30%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: cover;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 664px) and (max-width: 700px){
  .selling {
    width: 100% ;
    height: 80%;
    margin-top: 5%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 380px;
    height: 420px;
    background: #ffffff;
    position: relative;
    z-index: 1;
    margin-left: 11%;
  }
  .first_img {
    width: 84px;
    height: 90px;
    margin-left: -28px;
    margin-top: 38px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 80px;
    height: 90px;
    margin-left: -28px;
    margin-top: 28px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 80px;
    height: 87px;
    margin-left: -28px;
    margin-top: 36px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 380px;
    height: 375px;
    margin-left: -30%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: cover;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 484px) and (max-width: 664px){
  .selling {
    width: 100% ;
    height: 80%;
    margin-top: 5%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 77%;
    height: 400px;
    background: #ffffff;
    position: relative;
    z-index: 1;
    margin-left: 11%;
  }
  .first_img {
    width: 64px;
    height: 70px;
    margin-left: -28px;
    margin-top: 30px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 62px;
    height: 70px;
    margin-left: -28px;
    margin-top: 28px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 64px;
    height: 70px;
    margin-left: -28px;
    margin-top: 36px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
  }
  .selling_img {
    width: 77%;
    height: 575px;
    margin-left: 11%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: cover;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 441px) and (max-width: 484px){
  .selling {
    width: 100% ;
    height: 80%;
    margin-top: 5%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 77%;
    height: 340px;
    background: #ffffff;
    position: relative;
    z-index: 1;
    margin-left: 11%;
  }
  .first_img {
    width: 64px;
    height: 70px;
    margin-left: -28px;
    margin-top: 26px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 62px;
    height: 70px;
    margin-left: -28px;
    margin-top: 26px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 64px;
    height: 70px;
    margin-left: -28px;
    margin-top: 32px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 77%;
    height: 500px;
    margin-left: 11%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: cover;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 340px) and (max-width: 441px){
  .selling {
    width: 100% ;
    height: 80%;
    margin-top: 5%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 77%;
    height: 280px;
    background: #ffffff;
    position: relative;
    z-index: 1;
    margin-left: 11%;
  }
  .first_img {
    width: 60px;
    height: 65px;
    margin-left: -28px;
    margin-top: 18px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 57px;
    height: 65px;
    margin-left: -28px;
    margin-top: 15px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 60px;
    height: 65px;
    margin-left: -28px;
    margin-top: 20px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 42%;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 45%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 77%;
    height: 380px;
    margin-left: 11%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: cover;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}

@media screen and (min-width: 280px) and (max-width: 340px){
  .selling {
    width: 100% ;
    height: 80%;
    margin-top: 5%;
  }
  .First_text {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    /* margin-top: 5%; */
    /* /* padding: 0%;
    margin: 0%; */
    /* display: flex;
    flex-direction: column;  */
  }
  .center_text {
    margin-top: 2%;
    width: 77%;
    height: 280px;
    background: #ffffff;
    position: relative;
    z-index: 1;
    margin-left: 11%;
  }
  .first_img {
    width: 52px;
    height: 57px;
    margin-left: -28px;
    margin-top: 18px;
    background-size: contain;
    background-image: url("./../../Assets/Group 18.png");
  }
  .second_img {
    width: 51px;
    height: 58px;
    margin-left: -28px;
    margin-top: 15px;
    background-size: contain;
    background-image: url("./../../Assets/Group 7.png");
  }
  .third_img {
    width: 52px;
    height: 57px;
    margin-left: -28px;
    margin-top: 20px;
    background-size: contain;
    background-image: url("./../../Assets/Group 19.png");
  }
  .client_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 28%;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
    padding-top: 15%;
  }
  .client_para {
    color: #5f605d;
    font-family: "Poppins", sans-serif;
    font-size: 45%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selling_img {
    width: 77%;
    height: 350px;
    margin-left: 11%;
    margin-top: 4%;
    background-image: url("./../../Assets/SeelingHome.png");
    background-size: cover;
    /* position: relative;
    z-index: 1; */
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
  }
}
