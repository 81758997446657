.steps_main_class {
  width: auto;
  height: auto;
}
.fromtop_space{
  padding-top: 3%;
}
.Purchase{
  color: #252525;
  font-family: "Poppins", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.8px;
padding-top: 3%;
}
.purchase_img{
  width: 80px;
height: 80px;
  /* margin-top: 33%;
     margin-left: 15%; */
  background-size: cover;
  background-image: url("./../../Assets/Ticket Purchase.png");
}
.cash_img{
  width: 80px;
height: 80px;
  /* margin-top: 33%;
     margin-left: 15%; */
  background-size: cover;
  background-image: url("./../../Assets/Cash in Hand.png");
}
.plumber_img{
  width: 80px;
height: 80px;
  /* margin-top: 33%;
     margin-left: 15%; */
  background-size: cover;
  background-image: url("./../../Assets/Plumber.png");
}
.fees_img{
  width: 80px;
height: 80px;
  /* margin-top: 33%;
     margin-left: 15%; */
  background-size: cover;
  background-image: url("./../../Assets/No Hidden Fees.png");
}
.online_img{
  width: 80px;
height: 80px;
  /* margin-top: 33%;
     margin-left: 15%; */
  background-size: cover;
  background-image: url("./../../Assets/Online Support.png");
}
.rectangle_91{
    width: auto;
height: 180px;
border: 1px solid #000;
background: rgba(248, 248, 248, 0.40);
backdrop-filter: blur(5px);
margin-top: -7%;
}
.top_space {
  padding-top: 5%;
}
.second_space {
  padding-top: 3%;
}
.heading_text {
  color: #0e0e10;
  font-family: "Poppins", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.easy_text {
  color: #0e0e10;
  font-family: "Poppins", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.img_one {
  width: auto;
  height: 380px;
  /* margin-top: 33%;
     margin-left: 15%; */
  background-size: cover;
  background-position: center;
  background-image: url("./../../Assets/Rectangle 93.png");
}
.img_two {
    width: auto;
    height: 380px;
  /* margin-top: 33%;
     margin-left: 15%; */
  background-size: cover;
  background-position: center;
  background-image: url("./../../Assets/Rectangle 94.png");
}
.img_three {
    width: auto;
    height: 380px;
  /* margin-top: 33%;
     margin-left: 15%; */
  background-size: cover;
  background-position: center;
  background-image: url("./../../Assets/Rectangle 95.png");
}
.consul_img {
  width: 85px;
  height: 85px;
  /* margin-top: 33%;
     margin-left: 15%; */
  background-size: contain;
  background-image: url("./../../Assets/Consultation.png");
}
.Cheque_img{
    width: 85px;
  height: 85px;
  /* margin-top: 33%;
     margin-left: 15%; */
  background-size: contain;
  background-image: url("./../../Assets/Cheque.png");
}
.Check_Book_img{
    width: 85px;
  height: 85px;
  /* margin-top: 33%;
     margin-left: 15%; */
  background-size: contain;
  background-image: url("./../../Assets/Check Book.png");
}
.consul_heading{
    color: #0E0E10;
    font-family: "Poppins", sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.9px;
padding-top: 3%;
}
.consul_para{
    color: #5F605D;
    font-family: "Poppins", sans-serif;
    width:380px;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.7px;
text-align: justify;
}
.Endspace_end{
  padding-top: 5%;
}

@media screen and (min-width: 768px) and (max-width: 991px){
  .steps_main_class {
    width: auto;
    height: auto;
  }
  .fromtop_space{
    padding-top: 3%;
  }
  .Purchase{
    color: #252525;
    font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  padding-top: 3%;
  }
  .purchase_img{
    width: 78px;
  height: 78px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Ticket Purchase.png");
  }
  .cash_img{
    width: 78px;
  height: 78px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Cash in Hand.png");
  }
  .plumber_img{
    width: 78px;
  height: 78px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Plumber.png");
  }
  .fees_img{
    width: 78px;
  height: 78px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/No Hidden Fees.png");
  }
  .online_img{
    width: 78px;
  height: 78px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Online Support.png");
  }
  .rectangle_91{
      width: auto;
  height: 150px;
  border: 1px solid #000;
  background: rgba(248, 248, 248, 0.40);
  backdrop-filter: blur(5px);
  margin-top: -7%;
  }
  .top_space {
    padding-top: 5%;
  }
  .second_space {
    padding-top: 3%;
  }
  .heading_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  .easy_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .img_one {
    width: auto;
    height: 380px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 93.png");
  }
  .img_two {
      width: auto;
      height: 380px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 94.png");
  }
  .img_three {
      width: auto;
      height: 380px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 95.png");
  }
  .consul_img {
    width: 78px;
    height: 78px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: contain;
    background-image: url("./../../Assets/Consultation.png");
  }
  .Cheque_img{
      width: 78px;
    height: 78px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: contain;
    background-image: url("./../../Assets/Cheque.png");
  }
  .Check_Book_img{
      width: 78px;
    height: 78px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: contain;
    background-image: url("./../../Assets/Check Book.png");
  }
  .consul_heading{
      color: #0E0E10;
      font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.9px;
  padding-top: 3%;
  }
  .consul_para{
      color: #5F605D;
      font-family: "Poppins", sans-serif;
      width:380px;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: justify;
  }
  .Endspace_end{
    padding-top: 5%;
  }
  
}

@media screen and (min-width: 500px) and (max-width: 767px){
  .steps_main_class {
    width: auto;
    height: auto;
  }
  .fromtop_space{
    padding-top: 3%;
  }
  .Purchase{
    color: #252525;
    font-family: "Poppins", sans-serif;
  font-size: 6px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  padding-top: 3%;
  }
  .purchase_img{
    width: 52px;
  height: 52px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Ticket Purchase.png");
  }
  .cash_img{
    width: 52px;
    height: 52px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Cash in Hand.png");
  }
  .plumber_img{
    width: 52px;
  height: 52px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Plumber.png");
  }
  .fees_img{
    width: 52px;
  height: 52px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/No Hidden Fees.png");
  }
  .online_img{
    width: 52px;
  height: 52px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Online Support.png");
  }
   .rectangle_91{
     width: auto;
  height: 100px;
  border: 1px solid #000;
  background: rgba(248, 248, 248, 0.40);
  backdrop-filter: blur(5px);
  margin-top: -7%;
  }
  .top_space {
    padding-top: 5%;
  }
  .second_space {
    padding-top: 3%;
  }
  .heading_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  .easy_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .img_one {
    width: auto;
    height: 320px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 93.png");
  }
  
  .img_two {
      width: auto;
      height: 320px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 94.png");
  }
  .img_three {
      width: auto;
      height: 320px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 95.png");
  }
  .consul_img {
    width: 60px;
    height: 60px;
    /* margin-top: 33%;*/
       margin-left: 40%; 
    background-size: contain;
    background-image: url("./../../Assets/Consultation.png");
  }
  .Cheque_img{
      width: 60px;
    height: 60px;
    /* margin-top: 33%;
       margin-left: 15%; */
       margin-left: 40%;
    background-size: contain;
    background-image: url("./../../Assets/Cheque.png");
  }
  .Check_Book_img{
    width: 60px;
    height: 60px;
    /* margin-top: 33%;
       margin-left: 15%; */
       margin-left: 40%;
    background-size: contain;
    background-image: url("./../../Assets/Check Book.png");
  }
  .consul_heading{
      color: #0E0E10;
      font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.9px;
 text-align: center;
  padding-top: 3%;
  }
  .consul_para{
      color: #5F605D;
      font-family: "Poppins", sans-serif;
      width:auto;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  /* text-align: justify; */
  }
  .Endspace_end{
    padding-top: 5%;
  }
  
}

@media screen and (min-width: 331px) and (max-width: 499px){
  .steps_main_class {
    width: auto;
    height: auto;
  }
  .fromtop_space{
    padding-top: 3%;
  }
  .Purchase{
    color: #252525;
    font-family: "Poppins", sans-serif;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  padding-top: 3%;
  text-align:justify;
  }
  .purchase_img{
    width: 32px;
  height: 32px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Ticket Purchase.png");
  }
  .cash_img{
    width: 32px;
    height: 32px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Cash in Hand.png");
  }
  .plumber_img{
    width: 32px;
    height: 32px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Plumber.png");
  }
  .fees_img{
    width: 32px;
    height: 29px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/No Hidden Fees.png");
  }
  .online_img{
    width: 32px;
    height: 32px;
    /* margin-top: 33%;*/
       /* margin-left: 15%;  */
    background-size: cover;
    display: flex;
    justify-content:left;
    background-image: url("./../../Assets/Online Support.png");
  }
   .rectangle_91{
     width: auto;
  height: 80px;
  border: 1px solid #000;
  background: rgba(248, 248, 248, 0.40);
  backdrop-filter: blur(5px);
  margin-top: -7%;
  }
  .top_space {
    padding-top: 5%;
  }
  .second_space {
    padding-top: 3%;
  }
  .heading_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  .easy_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .img_one {
    width: auto;
    height: 320px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 93.png");
  }
  
  .img_two {
      width: auto;
      height: 320px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 94.png");
  }
  .img_three {
      width: auto;
      height: 320px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 95.png");
  }
  .consul_img {
    width: 60px;
    height: 60px;
    /* margin-top: 33%;*/
       margin-left: 40%; 
    background-size: contain;
    background-image: url("./../../Assets/Consultation.png");
  }
  .Cheque_img{
      width: 60px;
    height: 60px;
    /* margin-top: 33%;
       margin-left: 15%; */
       margin-left: 40%;
    background-size: contain;
    background-image: url("./../../Assets/Cheque.png");
  }
  .Check_Book_img{
    width: 60px;
    height: 60px;
    /* margin-top: 33%;
       margin-left: 15%; */
       margin-left: 40%;
    background-size: contain;
    background-image: url("./../../Assets/Check Book.png");
  }
  .consul_heading{
      color: #0E0E10;
      font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.9px;
 text-align: center;
  padding-top: 3%;
  }
  .consul_para{
      color: #5F605D;
      font-family: "Poppins", sans-serif;
      width:auto;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  /* text-align: justify; */
  }
  .Endspace_end{
    padding-top: 5%;
  }
  
}

@media screen and (min-width: 320px) and (max-width: 330px){
  .steps_main_class {
    width: auto;
    height: auto;
  }
  .fromtop_space{
    padding-top: 3%;
  }
  .Purchase{
    color: #252525;
    font-family: "Poppins", sans-serif;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  padding-top: 3%;
  text-align:justify;
  }
  .purchase_img{
    width: 32px;
  height: 28px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Ticket Purchase.png");
  }
  .cash_img{
    width: 32px;
    height: 28px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Cash in Hand.png");
  }
  .plumber_img{
    width: 32px;
    height: 28px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/Plumber.png");
  }
  .fees_img{
    width: 32px;
    height: 28px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-image: url("./../../Assets/No Hidden Fees.png");
  }
  .online_img{
    width: 32px;
    height: 28px;
    /* margin-top: 33%;*/
       /* margin-left: 15%;  */
    background-size: cover;
    display: flex;
    justify-content:left;
    background-image: url("./../../Assets/Online Support.png");
  }
   .rectangle_91{
     width: auto;
  height: 80px;
  border: 1px solid #000;
  background: rgba(248, 248, 248, 0.40);
  backdrop-filter: blur(5px);
  margin-top: -7%;
  }
  .top_space {
    padding-top: 5%;
  }
  .second_space {
    padding-top: 3%;
  }
  .heading_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  .easy_text {
    color: #0e0e10;
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .img_one {
    width: auto;
    height: 320px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 93.png");
  }
  
  .img_two {
      width: auto;
      height: 320px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 94.png");
  }
  .img_three {
      width: auto;
      height: 320px;
    /* margin-top: 33%;
       margin-left: 15%; */
    background-size: cover;
    background-position: center;
    background-image: url("./../../Assets/Rectangle 95.png");
  }
  .consul_img {
    width: 60px;
    height: 60px;
    /* margin-top: 33%;*/
       margin-left: 40%; 
    background-size: contain;
    background-image: url("./../../Assets/Consultation.png");
  }
  .Cheque_img{
      width: 60px;
    height: 60px;
    /* margin-top: 33%;
       margin-left: 15%; */
       margin-left: 40%;
    background-size: contain;
    background-image: url("./../../Assets/Cheque.png");
  }
  .Check_Book_img{
    width: 60px;
    height: 60px;
    /* margin-top: 33%;
       margin-left: 15%; */
       margin-left: 40%;
    background-size: contain;
    background-image: url("./../../Assets/Check Book.png");
  }
  .consul_heading{
      color: #0E0E10;
      font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.9px;
 text-align: center;
  padding-top: 3%;
  }
  .consul_para{
      color: #5F605D;
      font-family: "Poppins", sans-serif;
      width:auto;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.7px;
  text-align: center;
  /* text-align: justify; */
  }
  .Endspace_end{
    padding-top: 5%;
  }
  
}