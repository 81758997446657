.logo-home{
    width: 100px;
    height: 100px;
}
.button_end_of_navbar{
    width: 280px;
    height: 62px;
    border-radius: 10px;
    border: 0.5px solid #0E0E10;
    background: #FFF;
    box-shadow: 1px 2px #000000;
}
.phone-icon{
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    margin-top:16px;
    margin-left:10px;
}
.HOME-OFFER{
    color: #0E0E10;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-top:24px;
    margin-left:80px;
}
.DIRECT{
    color: #484848;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.7px;
    margin-top:-10px;
    margin-left:110px;
}
.contact_us_for_free{
    color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
    text-align:center;
    margin-top:10px;
}
.phone_number_in_navbar{
    color: #0E0E10;
font-family: 'Poppins';
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.48px;
text-align:center;
margin-top:-13px;
}
.center_navbar_text{
    margin-left: 28%;
}
.why-us{
    color: #0E0E10;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.36px;
margin-left: 10px;
}
.reviews{
    color: #0E0E10;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 10px;
}
.faqs{
    color: #0E0E10;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.36px;
margin-left: 10px;
}
.How-It-Works{
    color: #0E0E10;
font-family: 'Poppins';
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.36px;
margin-left: -130px;
} 
a:hover {
    text-decoration: underline;
  }
  /* 768, 992 */
  @media screen and (min-width: 1024px) and (max-width: 1200px){
    .logo-home{
        width: 100px;
    height: 100px;
    }
    .button_end_of_navbar{
        width: 220px;
    height: 62px;
    border-radius: 10px;
    border: 0.5px solid #0E0E10;
    background: #FFF;
    box-shadow: 1px 2px #000000;
    }
    .phone-icon{
        width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-top:16px;
    margin-left:10px;
    }
    .HOME-OFFER{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-top:24px;
        margin-left:40px;
    }
    .DIRECT{
        color: #484848;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.7px;
        margin-top:-10px;
        margin-left:70px;
    }
    .contact_us_for_free{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.36px;
        text-align:center;
        margin-top:10px;
    }
    .phone_number_in_navbar{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align:center;
    margin-top:-13px;
    }
    .center_navbar_text{
        margin-left: 28%;
    }
    .why-us{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: -15px;
    }
    .reviews{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
        margin-left: 10px;
    }
    .faqs{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 10px;
    }
    .How-It-Works{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: -130px;
    } 
  }

  @media screen and (min-width: 993px) and (max-width: 1024px){
    .logo-home{
        width: 100px;
    height: 100px;
    }
    .button_end_of_navbar{
        width: 220px;
    height: 62px;
    border-radius: 10px;
    border: 0.5px solid #0E0E10;
    background: #FFF;
    box-shadow: 1px 2px #000000;
    }
    .phone-icon{
        width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-top:16px;
    margin-left:10px;
    }
    .HOME-OFFER{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-top:24px;
        margin-left:40px;
    }
    .DIRECT{
        color: #484848;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.7px;
        margin-top:-10px;
        margin-left:70px;
    }
    .contact_us_for_free{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.36px;
        text-align:center;
        margin-top:10px;
    }
    .phone_number_in_navbar{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align:center;
    margin-top:-13px;
    }
    .center_navbar_text{
        margin-left: 28%;
    }
    .why-us{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: -15px;
    }
    .reviews{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
        margin-left: 10px;
    }
    .faqs{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 10px;
    }
    .How-It-Works{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: -130px;
    } 
  }
  @media screen and (max-width: 992px){
    .logo-home{
        width: 100px;
    height: 100px;
    }
    .button_end_of_navbar{
        width: 220px;
    height: 62px;
    border-radius: 10px;
    border: 0.5px solid #0E0E10;
    background: #FFF;
    box-shadow: 1px 2px #000000;
    }
    .phone-icon{
        width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-top:16px;
    margin-left:10px;
    }
    .HOME-OFFER{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-top:24px;
        margin-left:40px;
    }
    .DIRECT{
        color: #484848;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.7px;
        margin-top:-10px;
        margin-left:70px;
    }
    .contact_us_for_free{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.36px;
        text-align:center;
        margin-top:10px;
    }
    .phone_number_in_navbar{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align:center;
    margin-top:-13px;
    }
    .center_navbar_text{
        margin-left: 28%;
    }
    .why-us{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: -15px;
    }
    .reviews{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
        margin-left: 10px;
    }
    .faqs{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 10px;
    }
    .How-It-Works{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: -130px;
    } 
  }

  @media screen and (min-width: 769px) and (max-width: 991px){
    .logo-home{
    width: 100px;
    height: 100px;
    }
    .button_end_of_navbar{
    width: 220px;
    height: 62px;
    border-radius: 10px;
    border: 0.5px solid #0E0E10;
    background: #FFF;
    box-shadow: 1px 2px #000000;
    margin-left:250px;
    }
    .phone-icon{
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-top:16px;
    margin-left:10px;
    }
    .HOME-OFFER{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-top:24px;
        margin-left:-10px;
    }
    .DIRECT{
        color: #484848;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.7px;
        margin-top:-10px;
        margin-left:10px;
    }
    .contact_us_for_free{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.36px;
        text-align:center;
        margin-top:10px;
    }
    .phone_number_in_navbar{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align:center;
    margin-top:-13px;
    }
    .center_navbar_text{
        margin-left: 28%;
    }
    .why-us{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 120px;
    }
    .reviews{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
        margin-left: 120px;
    }
    .faqs{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 120px;
    }
    .How-It-Works{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 120px;
    } 
  }

  @media screen and (min-width: 484px) and (max-width: 769px){
    .logo-home{
        width: 100px;
    height: 100px;
    }
    .button_end_of_navbar{
        width: 220px;
    height: 62px;
    border-radius: 10px;
    border: 0.5px solid #0E0E10;
    background: #FFF;
    box-shadow: 1px 2px #000000;
    margin-left: 150px;
    }
    .phone-icon{
        width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-top:16px;
    margin-left:10px;
    }
    .HOME-OFFER{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-top:20px;
        margin-left:-30px;
    }
    .DIRECT{
        color: #484848;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.7px;
        margin-top:-10px;
        margin-left:-10px;
    }
    .contact_us_for_free{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.36px;
        text-align:center;
        margin-top:10px;
        margin-left:10px;
    }
    .phone_number_in_navbar{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align:center;
    /* margin-top:px; */
        margin-left:10px;
    }
    .center_navbar_text{
        margin-left: 28%;
    }
    .why-us{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 80px;
    /* text-align:center; */
    }
    .reviews{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
        /* margin-left: 120px; */
        margin-left: 80px;
    /* text-align:center; */
    }
    .faqs{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    /* margin-left: 120px; */
    margin-left: 80px;
    /* text-align:center; */
    }
    .How-It-Works{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    /* margin-left: 120px; */
    margin-left: 80px;
    /* text-align:center; */
    } 
  }

  @media screen and (min-width: 441px) and (max-width: 484px){
    .logo-home{
        width: 100px;
    height: 100px;
    }
    .button_end_of_navbar{
        width: 220px;
    height: 62px;
    border-radius: 10px;
    border: 0.5px solid #0E0E10;
    background: #FFF;
    box-shadow: 1px 2px #000000;
    margin-left: 120px;
    }
    .phone-icon{
        width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-top:16px;
    margin-left:10px;
    }
    .HOME-OFFER{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-top:30px;
        margin-left:-30px;
    }
    .DIRECT{
        color: #484848;
        font-family: 'Poppins';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.7px;
        margin-top:20px;
        margin-left:-10px;
    }
    .contact_us_for_free{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.36px;
        text-align:center;
        margin-top:10px;
        margin-left:10px;
    }
    .phone_number_in_navbar{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align:center;
    margin-top:-10px;
        margin-left:10px;
    }
    .center_navbar_text{
        margin-left: 28%;
    }
    .why-us{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 70px;
    /* text-align:center; */
    }
    .reviews{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
        /* margin-left: 120px; */
        margin-left: 70px;
    /* text-align:center; */
    }
    .faqs{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    /* margin-left: 120px; */
    margin-left: 70px;
    /* text-align:center; */
    }
    .How-It-Works{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    /* margin-left: 120px; */
    margin-left: 70px;
    /* text-align:center; */
    } 
  }
  
  @media screen and (min-width: 340px) and (max-width: 441px){
    .logo-home{
        width: 70px;
    height: 70px;
    }
    .button_end_of_navbar{
        width: 200px;
    height: 62px;
    border-radius: 10px;
    border: 0.5px solid #0E0E10;
    background: #FFF;
    box-shadow: 1px 2px #000000;
    margin-left: 70px;
    }
    .phone-icon{
        width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-top:16px;
    margin-left:10px;
    }
    .HOME-OFFER{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-top:30px;
        margin-left:-30px;
    }
    .DIRECT{
        color: #484848;
        font-family: 'Poppins';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.7px;
        margin-top:20px;
        margin-left:-10px;
    }
    .contact_us_for_free{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.36px;
        text-align:center;
        margin-top:10px;
        margin-left:10px;
    }
    .phone_number_in_navbar{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align:center;
    margin-top:-10px;
        margin-left:10px;
    }
    .center_navbar_text{
        margin-left: 28%;
    }
    .why-us{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 40px;
    /* text-align:center; */
    }
    .reviews{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
        /* margin-left: 120px; */
        margin-left: 40px;
    /* text-align:center; */
    }
    .faqs{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    /* margin-left: 120px; */
    margin-left: 40px;
    /* text-align:center; */
    }
    .How-It-Works{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    /* margin-left: 120px; */
    margin-left: 40px;
    /* text-align:center; */
    } 
  }

  @media screen and (min-width: 280px) and (max-width: 340px){
    .logo-home{
        width: 50px;
    height: 50px;
    }
    .button_end_of_navbar{
        width: 180px;
    height: 55px;
    border-radius: 10px;
    border: 0.5px solid #0E0E10;
    background: #FFF;
    box-shadow: 1px 2px #000000;
    margin-left: 40px;
    }
    .phone-icon{
        width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-top:16px;
    margin-left:10px;
    }
    .HOME-OFFER{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
        margin-top:20px;
        margin-left:-10px;
    }
    .DIRECT{
        color: #484848;
        font-family: 'Poppins';
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.7px;
        margin-top:10px;
        margin-left:8px;
    }
    .contact_us_for_free{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.36px;
        text-align:center;
        margin-top:10px;
        margin-left:10px;
    }
    .phone_number_in_navbar{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
    text-align:center;
    margin-top:-10px;
        margin-left:10px;
    }
    .center_navbar_text{
        margin-left: 28%;
    }
    .why-us{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-left: 20px;
    /* text-align:center; */
    }
    .reviews{
        color: #0E0E10;
        font-family: 'Poppins';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
        /* margin-left: 120px; */
        margin-left: 20px;
    /* text-align:center; */
    }
    .faqs{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    /* margin-left: 120px; */
    margin-left: 20px;
    /* text-align:center; */
    }
    .How-It-Works{
        color: #0E0E10;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    /* margin-left: 120px; */
    margin-left: 20px;
    /* text-align:center; */
    } 
  }
  